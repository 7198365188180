const apiErrorMessage = require('@/libraries/DigiTickets/Api/apiErrorMessage');
const { basicHydrate, createModelBasicHydrator } = require('@/libraries/DigiTickets/Hydration/basicHydrator');

/**
 * @abstract
 *
 * In the doc blocks here a "thing" means what type of thing is stored by this repository, e.g. Tickets, Products,
 * Marketing Preferences, etc.
 *
 * Your subclass needs to implement all the @abstract methods.
 */
class AbstractRepository {
    /**
     * @param {DataStore} dataStore
     * @param {Hydrator} hydrator
     * @param {SyncStateManager} syncStateManager
     */
    constructor(
        dataStore,
        hydrator,
        syncStateManager
    ) {
        /**
         * @protected
         * @type {DataStore}
         */
        this.dataStore = dataStore;

        /**
         * @protected
         * @type {Hydrator}
         */
        this.hydrator = hydrator;

        /**
         * @protected
         * @type {SyncStateManager}
         */
        this.syncStateManager = syncStateManager;
    }

    /**
     * @abstract
     * @protected
     *
     * Return the human readable name of the thing this repository stores.
     * e.g:
     * return "Ticket";
     *
     * @return {string}
     */
    getEntityName() {
        throw new Error('getEntityName has not been implemented.');
    }

    /**
     * @abstract
     * @protected
     *
     * Return the name of the Dexie store for this thing.
     * e.g:
     * return DataStore.STORE_NAMES.TICKETS;
     *
     * @return {string}
     */
    getTableName() {
        throw new Error('getTableName has not been implemented.');
    }

    /**
     * @abstract
     * @protected
     *
     * Return the class for the thing this repository stores.
     * e.g.
     * return Ticket;
     *
     * @return {function|Class}
     */
    getEntityClass() {
        throw new Error('getEntityClass has not been implemented.');
    }

    /**
     * @protected
     *
     * Makes an API request to fetch all of the items that should be stored for this "thing".
     * You must implement this when extending AbstractRepository. Your method should an array of *hydrated* models, not
     * just the raw API data.
     *
     * @return {Promise<Array>}
     */
    loadFromApi() {
        let resource = this.getResource();
        if (!resource || !resource.hasOwnProperty('query')) {
            throw new Error('Either getResource should return an object with a query method or you should override loadFromApi.');
        }

        return new Promise((resolve, reject) => {
            resource.query(
                {},
                (response) => {
                    resolve(
                        this.hydrator.hydrateArray(
                            response,
                            () => new (this.getEntityClass())()
                        )
                    );
                },
                (err) => reject(new Error(apiErrorMessage(err)))
            );
        });
    }

    /**
     * Instead of having to implement loadFromApi in every subclass you can return an Angular Resource from this method
     * in a subclass (or any object with a 'query' method') and it will be used to fetch the items from the
     * API during a sync.
     *
     * @protected
     * @return {null|{query: function(params: object, success:function, error:function)}}
     */
    getResource() {
        return null;
    }

    /**
     * Remove all rows from the table.
     *
     * @return {Dexie.Promise}
     */
    clear() {
        this.syncStateManager.remove(this.getTableName());

        return this.dataStore.clear(this.getTableName());
    }

    /**
     * Get all rows from the table, ignoring their status.
     *
     * @return {Promise<[]>}
     */
    findAll() {
        return this.dataStore
            .findAll(this.getTableName())
            .then(createModelBasicHydrator(this.getEntityClass()));
    }

    /**
     * Returns all rows from the table that have an Active status.
     *
     * @return {Dexie.Promise<[]>}
     */
    findAllActive() {
        return this.dataStore
            .findWhere(this.getTableName(), 'status', 'Active')
            .then(createModelBasicHydrator(this.getEntityClass()));
    }

    /**
     * Find the entry with the given ID in the table.
     * If found it will populate a new instance of the appropriate model and return that.
     *
     * @param {number|string} id
     *
     * @return {Promise<object>}
     */
    find(id) {
        id = parseInt(id, 10); // Must be a number. Strings will not be found.

        return this.dataStore.find(this.getTableName(), id)
            .then((data) => {
                if (!data) {
                    // Throwing an error rejects the promise.
                    throw new Error(`${this.getEntityName()} ${id} was not found.`);
                }

                return basicHydrate(data, new (this.getEntityClass())());
            });
    }

    /**
     * @param {number} branchID
     *
     * @return {Promise}
     */
    sync(branchID) {
        return this.loadFromApi()
            .then((models) => {
                return this.clear()
                    .then(() => this.dataStore.persistMany(this.getTableName(), models));
            })
            .then(() => this.saveLastSync(branchID));
    }

    /**
     * @private
     *
     * Saves the date and branch the last sync happened for in local storage.
     *
     * @param {number} branchID
     */
    saveLastSync(branchID) {
        this.syncStateManager.saveLastSync(this.getTableName(), branchID);
    }
}

module.exports = AbstractRepository;
