const AbstractItem = require('@/lib/Items/AbstractItem');

class Surcharge extends AbstractItem {
    constructor() {
        super();

        this.icon = 'fa-coins';
        this.itemType = DigiTickets.ItemType.SURCHARGE;
    }
}

module.exports = Surcharge;
