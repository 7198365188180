const AbstractRepository = require('@/lib/Repositories/AbstractRepository');
const DataStoreNames = require('@/libraries/DigiTickets/DataStores/DataStoreNames');
const MarketingPreference = require('@/models/MarketingPreference');

class MarketingPreferenceRepository extends AbstractRepository {
    /**
     * @param {DataStore} dataStore
     * @param {Hydrator} hydrator
     * @param {SyncStateManager} syncStateManager
     * @param MarketingPreferenceResource
     */
    constructor(
        dataStore,
        hydrator,
        syncStateManager,
        MarketingPreferenceResource
    ) {
        super(
            dataStore,
            hydrator,
            syncStateManager
        );

        this.marketingPreferenceResource = MarketingPreferenceResource;
    }

    /**
     * @protected
     */
    getEntityName() {
        return 'Marketing Preference';
    }

    /**
     * @protected
     */
    getEntityClass() {
        return MarketingPreference;
    }

    /**
     * @protected
     */
    getTableName() {
        return DataStoreNames.MARKETING_PREFERENCES;
    }

    /**
     * @protected
     * @return {{query: function(params: object, success:function, error:function)}}
     */
    getResource() {
        return this.marketingPreferenceResource;
    }
}

module.exports = MarketingPreferenceRepository;
