/**
 * Attempt to return a useful error message from a failed API request.
 * This can handle the structure of the 'error' parameter from a failed angular-resource request.
 * So you can use it like this:
 * someResource.query(
 *     {},
 *     (result) => // ...,
 *     (err) => reject(new Error(apiErrorMessage(err)))
 * );
 *
 * @param result
 *
 * @return {string}
 */
const apiErrorMessage = (result) => {
    let reason = 'Request failed';
    let httpStatus = [];

    if (result && typeof result === 'object') {
        if (result.data && result.data.error) {
            reason = result.data.error;
        } else if (result.data && result.data.message) {
            reason = result.data.message;
        }

        // Get the status text and status code from the result object.
        httpStatus = [
            result.statusText,
            result.status,
        ].filter((val) => val && (typeof val === 'string' || typeof val === 'number'));
    } else if (result && typeof result === 'string') {
        reason = result;
    }

    return reason + (httpStatus.length > 0 ? ` (${httpStatus.join(' ')})` : '');
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = apiErrorMessage;
}
