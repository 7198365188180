const EntityStatus = Object.freeze({
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    ARCHIVED: 'Archived',
    DELETED: 'Deleted'
});

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = EntityStatus;
}
