const CustomerScreenQuestion = require('./CustomerScreenQuestion');
const CustomerScreenQuestionType = require('./CustomerScreenQuestionType');
const { toInt, toString } = require('../functions/transform');

const MarketingPreference = function () {
    /**
     * @type {?number}
     */
    this.ID = null;

    /**
     * @type {string}
     */
    this.name = '';

    /**
     * @type {string}
     */
    this.question = '';

    /**
     *
     * @type {string}
     */
    this.status = '';
};

MarketingPreference.prototype = {
    getHydrationMap() {
        return {
            ID: {
                field: ['ID', 'marketingPreferenceID'],
                transform: toInt
            },
            name: toString,
            question: toString,
            status: toString
        };
    },

    /**
     * @return {CustomerScreenQuestion}
     */
    toCustomerScreenQuestion() {
        return new CustomerScreenQuestion(
            CustomerScreenQuestionType.BOOLEAN,
            this.question
        );
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = MarketingPreference;
}
