const DataStoreNames = Object.freeze({
    CATEGORIES: 'categories',
    MARKETING_PREFERENCES: 'marketingPreferences',
    SESSIONS: 'sessions',
    SYNC_METADATA: 'syncMetadata',
    TEST: 'test',
    USER_SESSIONS: 'userSessions'
});

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = DataStoreNames;
}
