const AbstractRepository = require('@/lib/Repositories/AbstractRepository');
const Category = require('./Category');
const DataStoreNames = require('@/libraries/DigiTickets/DataStores/DataStoreNames');

class CategoryRepository extends AbstractRepository {
    /**
     * @param {DataStore} dataStore
     * @param {Hydrator} hydrator
     * @param {SyncStateManager} syncStateManager
     * @param categoryResource
     */
    constructor(
        dataStore,
        hydrator,
        syncStateManager,
        categoryResource
    ) {
        super(dataStore, hydrator, syncStateManager);

        this.categoryResource = categoryResource;
    }

    getEntityClass() {
        return Category;
    }

    getEntityName() {
        return 'Category';
    }

    getTableName() {
        return DataStoreNames.CATEGORIES;
    }

    getResource() {
        return this.categoryResource;
    }
}

module.exports = CategoryRepository;
