const AbstractItem = require('@/lib/Items/AbstractItem');

class DeliveryMethod extends AbstractItem {
    constructor() {
        super();

        this.icon = 'fa-truck';
        this.itemType = DigiTickets.ItemType.DELIVERY_METHOD;
    }
}

module.exports = DeliveryMethod;
