const AbstractItem = require('@/lib/Items/AbstractItem');
const { toBool } = require('@/functions/transform');

class Bundle extends AbstractItem {
    constructor() {
        super();

        this.singleDayBundle = false;
        this.itemType = DigiTickets.ItemType.BUNDLE;
    }

    /**
     * @returns {object}
     */
    getHydrationMap() {
        return Object.assign(
            this.getBaseHydrationMap(),
            {
                singleDayBundle: {
                    field: ['singleDayBundle', 'allowPriceEdit'],
                    transform: toBool
                }
            }
        );
    }
}

module.exports = Bundle;
