const EntityStatus = require('@/libraries/DigiTickets/Enums/EntityStatus');
const { toInt, toString } = require('@/functions/transform');

/**
 * @abstract
 */
class AbstractEntity {
    constructor() {
        /**
         * @type {Number}
         */
        this.ID = null;

        /**
         * @type {String}
         */
        this.status = null;

        /**
         * @type {number}
         */
        this.navOrder = 0;
    }

    /**
     * @return {boolean}
     */
    isActive() {
        return this.status === EntityStatus.ACTIVE;
    }

    getHydrationMap() {
        return {
            ID: toInt,
            navOrder: toInt,
            status: toString
        };
    }
}

module.exports = AbstractEntity;
