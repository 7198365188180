const AbstractEntity = require('@/lib/Entities/AbstractEntity');
const { toBool, toInt } = require('@/functions/transform');

class Category extends AbstractEntity {
    constructor() {
        super();

        /**
         * @type {string}
         */
        this.name = '';

        /**
         * TODO: Remove
         *
         * @type {DigiTickets.Product[]}
         */
        this.products = [];

        /**
         * TODO: Remove
         *
         * @type {DigiTickets.Ticket}
         */
        this.tickets = [];

        /**
         * @type {boolean}
         */
        this.allowPriceEdit = false;

        /**
         * @type {boolean}
         */
        this.hasFood = false;

        /**
         * @type {boolean}
         */
        this.hasNonFood = false;

        /**
         * @type {string}
         */
        this.confirmationText = '';
    }

    getHydrationMap() {
        return Object.assign(
            {},
            super.getHydrationMap(),
            {
                allowPriceEdit: toBool,
                confirmationText: {
                    transform(value, allData) {
                        if (!value) {
                            return '';
                        }

                        return allData.isHydrated ? value : value.replace(/(<([^>]+)>)/ig, '');
                    }
                },
                ID: {
                    field: ['ID', 'catID'],
                    transform: toInt
                },
                name: {}
            }
        );
    }
}

module.exports = Category;
