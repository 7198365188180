const AbstractItem = require('@/lib/Items/AbstractItem');

class OrderBalanceItem extends AbstractItem {
    constructor() {
        super();

        this.itemType = DigiTickets.ItemType.ORDER_BALANCE;

        /**
         * @type {DigiTickets.Order}
         */
        this.originalOrder = null;
    }

    /**
     * @returns {string}
     */
    getName() {
        return 'Balance for Order ' + this.getOriginalOrder().bookingRef;
    }

    /**
     * @param {Number} orderBalance
     */
    setPrice(orderBalance) {
        this.price = orderBalance;
    }

    /**
     * @param {DigiTickets.Order} order
     */
    setOriginalOrder(order) {
        this.originalOrder = order;
    }

    /**
     * @returns {DigiTickets.Order}
     */
    getOriginalOrder() {
        if (!this.originalOrder) {
            throw new Error('originalOrder has not been set on an OrderBalanceItem.');
        }

        return this.originalOrder;
    }

    /**
     * @return {object}
     */
    getHydrationMap() {
        return Object.assign(
            this.getBaseHydrationMap(),
            {
                originalOrder: {
                    model: DigiTickets.Order
                }
            }
        );
    }

    afterHydration() {
        // Call this to check for balance items being loaded without an original order.
        // You cannot complete the order if you don't know which order the balance is for!
        this.getOriginalOrder();
    }
}

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = OrderBalanceItem;
}
