/**
 * @param {string} type
 * @param {string} html
 * @param {array} [answers]
 */
const CustomerScreenQuestion = function (type, html, answers) {
    /**
     * A unique ID for this question, so we know which question is being responded to.
     * getTime alone isn't unique when sending multiple questions. This should be plenty.
     *
     * @type {string}
     */
    this.ID = (new Date()).getTime() + (Math.random().toString(36));

    /**
     * Is this a checkbox / text input / radio button etc.
     *
     * @type {string}
     */
    this.type = type;

    /**
     * The text to be displayed to the user. This should be the final HTML, not a language string.
     *
     * @type {string}
     */
    this.html = html;

    /**
     * The possible answers for the customer (where appropriate for the question type).
     */
    this.answers = answers || [];

    /**
     * Has the question been displayed by the customer screen (not necessarily currently).
     *
     * @type {boolean}
     */
    this.wasReceived = false;

    /**
     * Has the question been displayed by the screen (not necessarily currently).
     *
     * @type {boolean}
     */
    this.wasDisplayed = false;
};

CustomerScreenQuestion.prototype = {
    setReceived() {
        this.wasReceived = true;
    },
    setDisplayed() {
        this.wasDisplayed = true;
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = CustomerScreenQuestion;
}
