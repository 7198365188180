/**
 * Do a basic hydration - just set all the values on the object where the keys match the data.
 * This can be used where a model has already been hydrated but then was converted to a standard object
 * (e.g. after being stored in IndexedDB)
 * now we need to get it back to the correct class.
 *
 * @param {Object} data
 * @param {Object} model
 */
const basicHydrate = (data, model) => {
    Object.keys(data).forEach((key) => {
        if (model.hasOwnProperty(key)) {
            model[key] = data[key];
        }
        // 'isHydrated' is a special key added to a model during hydration.
        // 'hasOwnProperty' will fail for this because it is not defined on every model, it is just added by
        // the hydrator. Set it to true if and only if it was present in the data and was truthy.
        if (key === 'isHydrated' && !!data.isHydrated) {
            model.isHydrated = true;
        }
    });

    return model;
};

/**
 * Run basicHydrate on an array of data.
 *
 * @param {array} data
 * @param {function} modelCallback
 */
const basicHydrateArray = (data, modelCallback) => {
    let started = new Date();
    let results = data.map((datum) => basicHydrate(datum, modelCallback()));
    console.debug(`Basic hydrated ${data.length} models in ${(new Date()).getTime() - started.getTime()}ms`);
    return results;
};

/**
 * Returns a function that can be called to hydrate an array of data into the model type specified.
 * Useful for a promise chain.
 *
 * @param {Function} Model
 *
 * @return {function(array): array}
 */
const createModelBasicHydrator = (Model) => (data) => basicHydrateArray(data, () => new Model());

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = {
        basicHydrate,
        basicHydrateArray,
        createModelBasicHydrator
    };
}
