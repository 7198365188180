const is = require('../libraries/Is');
const moment = require('moment');

/**
 * @param value
 * @return {boolean}
 */
const toBool = (value) => {
    // In JS !!"false" and !!"0" = true because they're non-empty strings.
    // Handle those special cases.
    if (value === '0' || value === 'false' || value === 'no' || value === 'off') {
        return false;
    }

    return !!value;
};

/**
 * @param value
 * @return {?boolean}
 */
const toNullableBool = (value) => (value === null ? null : toBool(value));

/**
 * @param value
 * @return {?Date}
 */
const toDate = (value) => {
    if (!value) {
        return null;
    }

    if (value instanceof Date) {
        return value;
    }

    if (typeof value === 'string') {
        let d;

        if (value.match(/\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/)) {
            // This is a Y-m-d H:i:s date and causes issues in Safari. But we can use moment to parse it
            // and tell it the format.
            d = moment(value, 'YYYY-MM-DD hh:mm:ss').toDate();
        } else {
            d = moment(value).toDate();
        }

        // Check for invalid dates.
        if (!d || Number.isNaN(d.getTime())) {
            return null;
        }

        return d;
    }

    return null;
};

/**
 * @param value
 * @return {number}
 */
const toFloat = (value) => {
    if (!value) {
        return 0.00;
    }

    return parseFloat(value);
};

/**
 * @param value
 * @return {?number}
 */
const toNullableFloat = (value) => (value === null ? null : toFloat(value));

/**
 * @param value
 * @return {number}
 */
const toInt = (value) => {
    if (!value) {
        return 0;
    }

    return parseInt(value, 10);
};

/**
 * @param value
 * @return {?number}
 */
const toNullableInt = (value) => (value === null ? null : toInt(value));

/**
 * @param value
 * @return {number[]}
 */
const toIntArray = (value) => {
    if (!value) {
        return [];
    }

    let values = [];
    if (is.anArray(value)) {
        values = value;
    } else {
        values = String(value).split(',');
    }

    return values.map(toInt).filter((i) => !!i);
};

/**
 * @param value
 * @return {string}
 */
const toString = (value) => {
    if (!value) {
        return '';
    }

    if ((typeof value === 'object') && !(value instanceof Array)) {
        return JSON.stringify(value);
    }

    return String(value);
};

/**
 * @param value
 * @return {?string}
 */
const toNullableString = (value) => (value === null ? null : toString(value));

/**
 * @param {string|{}} value
 * @return {{}}
 */
const fromJson = (value) => {
    if (typeof value === 'string') {
        try {
            return JSON.parse(value);
        } catch (e) {
            console.error('Failed to parse JSON', value);
            return null;
        }
    }

    return value;
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = {
        toBool,
        toNullableBool,
        toDate,
        toFloat,
        toNullableFloat,
        toInt,
        toNullableInt,
        toIntArray,
        toString,
        toNullableString,
        fromJson
    };
}
