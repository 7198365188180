/**
 * The actual data for each type of synced item is stored in IndexedDB.
 * For each table we also store a small piece of meta data in local storage to say when that table was last synced.
 * This class is for managing that meta data.
 */
class SyncStateManager {
    /**
     * @param {LocalStorageDataStore} LocalStorageDataStore
     */
    constructor(LocalStorageDataStore) {
        this.localStorageDataStore = LocalStorageDataStore;
    }

    /**
     * Checks local storage to see if the data for this repository has been synced, and if it is for the
     * expected branch.
     * Returns the Date it was last synced if it exists.
     *
     * @param {string} tableName
     * @param {number} branchID
     *
     * @return {?Date}
     */
    isSynced(tableName, branchID) {
        let value = this.localStorageDataStore.find('lastSync.' + tableName);
        if (value && value.branchID === branchID) {
            return new Date(value.syncedAt);
        }

        return null;
    }

    /**
     * Saves the date and branch the last sync happened for in local storage.
     *
     * @param {string} tableName
     * @param {number} branchID
     */
    saveLastSync(tableName, branchID) {
        let value = {
            branchID,
            syncedAt: (new Date()).toISOString()
        };

        this.localStorageDataStore.persist('lastSync.' + tableName, value);
    }

    /**
     * Forget the sync meta data for a specific table.
     *
     * @param {string} tableName
     */
    remove(tableName) {
        this.localStorageDataStore.remove('lastSync.' + tableName);
    }

    /**
     * Removes all the sync meta data from local storage.
     * Call this then when you refresh ProPoint it will re-download the table data.
     */
    removeAll() {
        const keys = this.localStorageDataStore.getKeys();
        keys.forEach((key) => {
            if (key.indexOf('lastSync.') === 0) {
                this.localStorageDataStore.remove(key);
            }
        });
    }
}

module.exports = SyncStateManager;
