const { toFloat, toNullableFloat } = require('../functions/transform');

const GiftAidRate = function () {
    this.amount = 0;
    this.amountType = '';
    this.roundUpTo = null;
    this.name = '';
    this.status = false;
};

GiftAidRate.prototype = {
    getHydrationMap() {
        return {
            amount: toFloat,
            amountType: {},
            name: {},
            roundUpTo: toNullableFloat,
            status: {
                transform(value) {
                    return value === true || value === 'Active';
                }
            }
        };
    },

    /**
     * Return the amount to adjust a price by.
     * Can be negative to decrease the price!
     *
     * @return {number}
     */
    getAdjustmentAmount: function getAdjustmentAmount() {
        return this.amount;
    },

    /**
     * Percentage or fixed value adjustment?
     *
     * @return {string} 'Value' or 'Percentage'
     */
    getAdjustmentType: function getAdjustmentType() {
        return this.amountType;
    },

    /**
     * For percentage adjustments, round up or down?
     *
     * @return {string|null} 'Auto', 'Up', 'Down', or null
     */
    getAdjustmentRoundDirection: function getAdjustmentRoundDirection() {
        return 'Up';
    },

    /**
     * Nearest amount to round to (e.g. 0.10).
     *
     * @return {number|null}
     */
    getAdjustmentRoundTo: function getAdjustmentRoundTo() {
        return this.roundUpTo;
    },

    /**
     * @return {number|null}
     */
    getMaximumAdjustmentValue: function getMaximumAdjustmentValue() {
        return null;
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = GiftAidRate;
}
