/**
 * Provides methods for checking the type of variables.
 * e.g.
 * is.anArray(someVar);
 * is.aFunction(someVar);
 *
 * The naming is inspired by this package: https://github.com/arasatasaygin/is.js
 */
const Is = function () {
};

/**
 * Keys are quoted to not cause issues with names like 'function'.
 */
Is.prototype = {
    /**
     * @param {*} object
     * @return {boolean}
     */
    anArray: function anArray(object) {
        return object instanceof Array;
    },

    /**
     * @param {*} object
     * @return {boolean}
     */
    aFunction: function aFunction(object) {
        return typeof object === 'function';
    },

    /**
     * @param {*} object
     * @return {boolean}
     */
    aNumber: function aNumber(object) {
        return typeof object === 'number';
    },

    /**
     * @param {*} object
     * @return {boolean}
     */
    anObject: function anObject(object) {
        return typeof object === 'object';
    },

    /**
     * @param {*} object
     * @return {boolean}
     */
    aString: function aString(object) {
        return typeof object === 'string';
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = new Is();
}
/* istanbul ignore next */
if (typeof window === 'object') {
    window.is = new Is();
}
