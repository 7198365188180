/* eslint-disable global-require */

/**
 * In future this function will do everything that src/js/1-bootstrap.js does but as a function.
 * It will be imported into the entry point for each screen (customer.js, epos,js, kitchen.js) and
 * be executed from there.
 */
const bootstrap = () => {
    // Currently all we do here is make some packages globally available.
    global.ini = require('ini');

    global.AbstractItem = require('@/lib/Items/AbstractItem'); // Used by OrderBalanceItem
    global.Category = require('@/lib/Categories/Category');
    global.CategoryRepository = require('@/lib/Categories/CategoryRepository');
    global.GiftVoucher = require('@/lib/GiftVouchers/GiftVoucher');
    global.mapItemTypeToModel = require('@/lib/Items/mapItemTypeToModel');
    global.MarketingPreferenceRepository = require('@/lib/MarketingPreferences/MarketingPreferenceRepository');
    global.SyncStateManager = require('@/lib/DataStores/SyncStateManager');
};

module.exports = bootstrap;
