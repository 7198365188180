const Category = require('@/lib/Categories/Category');
const GiftAidRate = require('@/models/GiftAidRate');
const { toBool, toNullableBool, toFloat, toNullableFloat, toInt, toString, toNullableString } = require('@/functions/transform');

/**
 * Representation of a generic item.
 * This contains the properties shared between all item types
 * (i.e. what's on the items table rather than ticket/products table)
 */
class AbstractItem {
    constructor() {
        /**
         * @type {?Boolean}
         */
        this.allowPriceEdit = false;

        /**
         * @type {?Category}
         */
        this.category = null;

        /**
         * @type {?string}
         */
        this.description = null;

        /**
         * @type {boolean}
         */
        this.giftAid = false;

        /**
         * @type {?GiftAidRate}
         */
        this.giftAidRate = null;

        /**
         * A Fontawesome icon name to use to represent this item type.
         * This is only used in the cart and may not be set for every item type if we don't want those to display an
         * icon.
         *
         * @type {?string}
         */
        this.icon = null;

        /**
         * @type {Number}
         */
        this.ID = null;

        /**
         * @type {String}
         */
        this.itemType = null;

        /**
         * @type {string}
         */
        this.name = '';

        /**
         * @type {?Number}
         */
        this.navOrder = null;

        /**
         * @type {?Number}
         */
        this.offlinePrice = null;

        /**
         * Default payment pattern set to single but this will usually come from the API.
         *
         * @type {string}
         */
        this.paymentPattern = DigiTickets.PaymentPatterns.SINGLE;

        /**
         * @type {Number}
         */
        this.price = 0.00;

        /**
         * @type {?String}
         */
        this.shortName = null;

        /**
         * @type {string}
         */
        this.status = 'Active';
    }

    /**
     * @return {string}
     */
    getName() {
        return this.shortName || this.name;
    }

    /**
     * @returns {number}
     */
    getPrice() {
        return parseFloat(this.offlinePrice ? this.offlinePrice : this.price);
    }

    /**
     * This returns the portion of the hydration map that is shared between all item types.
     * i.e. the map for just the properties defined in this abstract item type.
     *
     * @protected
     *
     * @return {object}
     */
    getBaseHydrationMap() {
        return {
            allowPriceEdit: toNullableBool,
            category: {
                field: ['category', 'categories'],
                model: Category
            },
            description: toNullableString,
            giftAid: toBool,
            giftAidRate: {
                model: GiftAidRate
            },
            ID: {
                field: ['ID', 'itemID'],
                transform: toInt
            },
            name: toString,
            navOrder: toInt,
            offlinePrice: toNullableFloat,
            paymentPattern: toString,
            price: toFloat,
            shortName: toNullableString,
            status: toString
        };
    }

    /**
     * @return {object}
     */
    getHydrationMap() {
        return this.getBaseHydrationMap();
    }

    afterHydration() {
        // If allowPriceEdit is null inherit whatever is set on the category.
        if (this.allowPriceEdit === null) {
            this.allowPriceEdit = this.category ? !!this.category.allowPriceEdit : false;
        }
    }

    /**
     * @return {boolean}
     */
    isSessionBased() {
        return false;
    }
}

module.exports = AbstractItem;
