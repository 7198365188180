const AbstractItem = require('@/lib/Items/AbstractItem');
const Bundle = require('@/lib/Bundles/Bundle');
const DeliveryMethod = require('@/lib/Delivery/DeliveryMethod');
const GiftVoucher = require('@/lib/GiftVouchers/GiftVoucher');
const OrderBalanceItem = require('@/libraries/DigiTickets/Orders/OrderBalanceItem');
const Surcharge = require('@/lib/Surcharges/Surcharge');

const mapItemTypeToModel = (itemType) => {
    let itemTypes = {};
    itemTypes[DigiTickets.ItemType.BUNDLE] = Bundle;
    itemTypes[DigiTickets.ItemType.DELIVERY_METHOD] = DeliveryMethod;
    itemTypes[DigiTickets.ItemType.GIFT_VOUCHER] = GiftVoucher;
    itemTypes[DigiTickets.ItemType.MEMBERSHIP_PLAN] = DigiTickets.MembershipPlan;
    itemTypes[DigiTickets.ItemType.ORDER_BALANCE] = OrderBalanceItem;
    itemTypes[DigiTickets.ItemType.PRODUCT] = DigiTickets.Product;
    itemTypes[DigiTickets.ItemType.PRODUCT_FOOD] = DigiTickets.Product;
    itemTypes[DigiTickets.ItemType.SURCHARGE] = Surcharge;
    itemTypes[DigiTickets.ItemType.TICKET] = DigiTickets.Ticket;

    if (itemTypes.hasOwnProperty(itemType)) {
        return itemTypes[itemType];
    }

    console.warn(`Unknown item type ${itemType}`);
    // Return default item type.
    return AbstractItem;
};

module.exports = mapItemTypeToModel;
