const AbstractItem = require('@/lib/Items/AbstractItem');
const { toBool, toInt, toString } = require('@/functions/transform');

class GiftVoucher extends AbstractItem {
    constructor() {
        super();

        this.itemType = DigiTickets.ItemType.GIFT_VOUCHER;

        /**
         * @type {string[]}
         */
        this.availableDeliveryTypes = [];

        /**
         * @type {string}
         */
        this.collectRecipientMessage = DigiTickets.EntryRequirement.NOT_ALLOWED;

        /**
         * @type {string}
         */
        this.collectRecipientName = DigiTickets.EntryRequirement.NOT_ALLOWED;

        /**
         * @type {DigiTickets.GiftVoucherDenomination[]}
         */
        this.denominations = [];

        /**
         * @type {?number}
         */
        this.expiresAfter = null;

        /**
         * @type {?string}
         */
        this.expiresAfterUnit = null;

        /**
         * @type {String}
         */
        this.giftVoucherType = null;
    }

    /**
     * @return {string}
     */
    getExpiresAfterString() {
        return this.expiresAfter
            + ' '
            + (
                this.expiresAfter === 1
                    // Remove trailing 's'
                    ? this.expiresAfterUnit.substr(0, this.expiresAfterUnit.length - 1)
                    : this.expiresAfterUnit
            );
    }

    /**
     * @return {boolean}
     */
    isBalanceType() {
        return this.giftVoucherType === DigiTickets.GiftVoucherType.BALANCE;
    }

    /**
     * @return {boolean}
     */
    isExperienceType() {
        return this.giftVoucherType === DigiTickets.GiftVoucherType.EXPERIENCE;
    }

    /**
     * Returns a GiftVoucherDenomination that may exist on this voucher that matches the value given.
     *
     * @param {number} value
     *
     * @return {DigiTickets.GiftVoucherDenomination[]}
     */
    getDenominationsByValue(value) {
        return this.denominations.filter(d => d.denomination === value);
    }

    /**
     * @return {boolean}
     */
    canCollectRecipientMessage() {
        return this.collectRecipientMessage !== DigiTickets.EntryRequirement.NOT_ALLOWED;
    }

    /**
     * @return {boolean}
     */
    mustCollectRecipientMessage() {
        return this.collectRecipientMessage === DigiTickets.EntryRequirement.REQUIRED;
    }

    /**
     * @return {boolean}
     */
    canCollectRecipientName() {
        return this.collectRecipientName !== DigiTickets.EntryRequirement.NOT_ALLOWED;
    }

    /**
     * @return {boolean}
     */
    mustCollectRecipientName() {
        return this.collectRecipientName === DigiTickets.EntryRequirement.REQUIRED;
    }

    /**
     * @return {object}
     */
    getHydrationMap() {
        return Object.assign(
            this.getBaseHydrationMap(),
            {
                // Unlike other item types we don't inherit allowPriceEdit from the category for gift vouchers.
                // The allowPriceEdit field specifies if a custom balance can be entered for this voucher.
                allowPriceEdit: toBool,
                availableDeliveryTypes: {
                    transform(value) {
                        return typeof value === 'string' ? value.split(',') : value;
                    }
                },
                collectRecipientMessage: toString,
                collectRecipientName: toString,
                denominations: {
                    modelCollection: DigiTickets.GiftVoucherDenomination
                },
                expiresAfter: toInt,
                expiresAfterUnit: toString,
                giftVoucherType: toString
            }
        );
    }
}

module.exports = GiftVoucher;
